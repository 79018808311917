@import "~antd/dist/antd.css";

html,
body {
    scroll-behavior: smooth;

    .ant-btn:hover,
    .ant-btn:focus {
        color: #000;
        background-color: rgba(209, 209, 209, 0.8);
        border-color: #81be48;
    }
    .ant-pagination-item-active {
        & a {
            color: #81be48;
        }
        border-color: #81be48;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #81be48;
        border-color: #81be48;
    }

    .ant-picker-time-panel-cell-disabled {
        display: none;
    }

    .ant-picker-time-panel {
        border: 1px solid #f0f0f0;
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: #81be48;
    }

    @media (max-width: 480px) {
        .ant-picker-datetime-panel {
            flex-direction: column;
        }
        .ant-picker-time-panel .ant-picker-content {
            height: 70px;
        }

        .ant-picker-dropdown {
            width: 100%;
            height: 100%;
            text-align: center;
        }
    }
}
